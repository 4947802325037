<template>
    <!-- Desktop -->
    <v-dialog
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="showDialog"
        overlay-color="rgb(34, 43, 69)"
        persistent
        style="height: 100%;"
        :max-width="$vuetify.breakpoint.lgAndUp ? '1440px' : '960px'"
    >
        <v-card
            style="position: relative;"
            color="rgb(249, 251, 253)"
            min-height="calc(100vh - 76px)"
        >
            <v-card-text
                class="pa-6 pb-0"
                style="color: black;"
            >
                <!-- Close Button -->
                <v-btn
                    icon
                    style="position: absolute; right: 16px;"
                    @click="hideProfessionalServiceOverlay"
                    aria-label="close"
                >
                    <v-icon
                        color="black"
                        style="z-index: 10;"
                    >
                        {{ mdiCloseThick }}
                    </v-icon>
                </v-btn>
                <!-- Close Button -->

                <v-row>
                    <!-- Back Button & Service Name -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <v-btn
                            icon
                            @click="hideProfessionalServiceOverlay"
                            class="mb-1"
                        >
                            <v-icon
                                size="24"
                                color="#2E567A"
                                style="opacity: 0.65;"
                            >
                                {{ mdiArrowLeftThinCircleOutline }}
                            </v-icon>
                        </v-btn>
                        <v-icon
                            color="#2E567A"
                            size="30"
                            class="mr-1"
                        >
                            {{ mdiAccountSupervisorOutline }}
                        </v-icon>
                        {{ selectedProfessionalService.name }}
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-divider
                            style="width: 50%;"
                        />
                    </v-col>
                    <!-- Back Button & Service Name -->

                    <!-- Services Form -->
                    <v-col
                        cols="6"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="pb-0 os-15-sb"
                            >
                                Service Name
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedProfessionalService.name">{{ selectedProfessionalService.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No name added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Industry
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedProfessionalService.industry.id">{{ selectedProfessionalService.industry.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No industry added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Service Description
                                <br />
                                <div
                                    v-if="selectedProfessionalService.description"
                                    class="os-13-r pt-3"
                                    style="color: #2E567A;"
                                    v-html="selectedProfessionalService.description"
                                />
                                <div
                                    v-else-if="!selectedProfessionalService.description"
                                    class="os-12-r py-3"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                >
                                    No description added
                                </div>
                            </v-col>

                            <!-- Location -->
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Service Location
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedProfessionalService.work_location.id">{{ selectedProfessionalService.work_location.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No location added
                                    </span>
                                </div>
                            </v-col>
                                    
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                                v-if="selectedProfessionalService.work_location.id !== 2"
                            >
                                Addresses
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <v-row
                                        v-for="(item, index) in addresses"
                                        :key="index"
                                        justify="center"
                                        class="mx-3 my-1"
                                    >
                                        <v-col
                                            class="flex-grow-1 flex-shrink-0 text-truncate pa-0"
                                            align-self="center"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                                size="20"
                                                class="mb-1"
                                            >
                                                {{ mdiMapMarkerOutline }}
                                            </v-icon>
                                            <span>{{ item.address_line_1 }}</span>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>

                            <!-- Map -->
                            <v-col
                                cols="12"
                                class="pt-0"
                                v-if="selectedProfessionalService.work_location.id !== 2"
                            >
                                <general-map-component
                                    :mapMarkers="mapMarkers"
                                    :avatar="$store.state.user.userData.avatar"
                                />
                            </v-col>
                            <!-- Map -->
                            <!-- Location -->

                            <v-col
                                cols="12"
                                class="pt-0 os-15-sb"
                            >
                                Rate
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedProfessionalService.interval.id < 4">{{ selectedProfessionalService.currency.symbol }}{{ selectedProfessionalService.rate }} per {{ selectedProfessionalService.interval.name }}</span>
                                    <span>Quote Upon Request</span>
                                    <!-- <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No rate added
                                    </span> -->
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                    <!-- Image & Request Button -->
                    <v-col
                        class="os-13-r text-center px-0"
                        cols="3"
                    >
                        <div
                            style="min-height: 100px; display: flex; justify-content: center;"
                        >
                            <v-avatar
                                :size="selectedProfessionalService.image ? '100px' : '50px'"
                                class="mb-2"
                            >
                                <v-img
                                    :src="selectedProfessionalService.image ? selectedProfessionalService.image : '/general/FixerIndividualIcon.png'"
                                    contain
                                />
                            </v-avatar>
                        </div>
                        <div
                            class="py-6"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="$router.push(`/service/${professionalServiceToShow.id}/request`)"
                                v-if="$store.state.user.userData.id !== selectedProfessionalService.owner.id"
                            >
                                Request
                            </v-btn>
                        </div>
                    </v-col>
                    <!-- Image & Request Button -->
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-dialog
        v-else
        v-model="showDialog"
        overlay-color="rgb(34, 43, 69)"
        persistent
        style="height: 100%;"
        fullscreen
    >
        <v-card
            style="position: relative;"
            color="rgb(249, 251, 253)"
            min-height="100%"
        >
            <v-card-text
                class="pa-6 pb-0"
                style="color: black;"
            >
                <!-- Close Button -->
                <v-btn
                    icon
                    style="position: absolute; right: 16px;"
                    @click="hideProfessionalServiceOverlay"
                    aria-label="close"
                >
                    <v-icon
                        color="black"
                        style="z-index: 10;"
                    >
                        {{ mdiCloseThick }}
                    </v-icon>
                </v-btn>
                <!-- Close Button -->

                <v-row>
                    <!-- Back Button & Service Name -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <v-btn
                            icon
                            @click="hideProfessionalServiceOverlay"
                            class="mb-1"
                        >
                            <v-icon
                                size="24"
                                color="#2E567A"
                                style="opacity: 0.65;"
                            >
                                {{ mdiArrowLeftThinCircleOutline }}
                            </v-icon>
                        </v-btn>
                        <v-icon
                            color="#2E567A"
                            size="30"
                            class="mr-1"
                        >
                            {{ mdiAccountSupervisorOutline }}
                        </v-icon>
                        {{ selectedProfessionalService.name }}
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-divider />
                    </v-col>
                    <!-- Back Button & Service Name -->

                    <!-- Image & Edit Button -->
                    <v-col
                        class="os-13-r text-center px-0"
                        cols="12"
                    >
                        <div
                            style="min-height: 100px; display: flex; justify-content: center;"
                        >
                            <v-avatar
                                size="100"
                                class="mb-2"
                            >
                                <v-img
                                    :src="selectedProfessionalService.image ? selectedProfessionalService.image : '/general/FixerIndividualIcon.png'"
                                    contain
                                />
                            </v-avatar>
                        </div>
                        <div
                            class="pt-6"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="$router.push(`/service/${professionalServiceToShow.id}/request`)"
                                v-if="$store.state.user.userData.id !== selectedProfessionalService.owner.id"
                            >
                                Request
                            </v-btn>
                        </div>
                    </v-col>
                    <!-- Image & Edit Button -->
                    
                    <!-- Services Form -->
                    <v-col
                        cols="12"
                        class="pb-0 os-15-sb"
                        align-self="end"
                    >
                        Service Name
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedProfessionalService.name">{{ selectedProfessionalService.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No name added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0 os-15-sb"
                    >
                        Industry
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedProfessionalService.industry.id">{{ selectedProfessionalService.industry.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No industry added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0 os-15-sb"
                    >
                        Service Description
                        <br />
                        <div
                            v-if="selectedProfessionalService.description"
                            class="os-13-r pt-3"
                            style="color: #2E567A;"
                            v-html="selectedProfessionalService.description"
                        />
                        <div
                            v-else-if="!selectedProfessionalService.description"
                            class="os-12-r py-3"
                            style="color: rgba(0, 0, 0, 0.38);"
                        >
                            No description added
                        </div>
                    </v-col>

                    <!-- Location -->
                    <v-col
                        cols="12"
                        class="py-0 os-15-sb"
                    >
                        Service Location
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedProfessionalService.work_location.id">{{ selectedProfessionalService.work_location.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No location added
                            </span>
                        </div>
                    </v-col>
                                    
                    <v-col
                        cols="12"
                        class="py-0 os-15-sb"
                        v-if="selectedProfessionalService.work_location.id !== 2"
                    >
                        Addresses
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <v-row
                                v-for="(item, index) in addresses"
                                :key="index"
                                justify="center"
                                class="mx-3 my-1"
                            >
                                <v-col
                                    class="flex-grow-1 flex-shrink-0 text-truncate pa-0"
                                    align-self="center"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        size="20"
                                        class="mb-1"
                                    >
                                        {{ mdiMapMarkerOutline }}
                                    </v-icon>
                                    <span>{{ item.address_line_1 }}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>

                    <!-- Map -->
                    <v-col
                        cols="12"
                        class="pt-0"
                        v-if="selectedProfessionalService.work_location.id !== 2"
                    >
                        <general-map-component
                            :mapMarkers="mapMarkers"
                            :avatar="$store.state.user.userData.avatar"
                        />
                    </v-col>
                    <!-- Map -->
                    <!-- Location -->

                    <v-col
                        cols="12"
                        class="pt-0 os-15-sb"
                    >
                        Rate
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedProfessionalService.interval.id < 4">{{ selectedProfessionalService.currency.symbol }}{{ selectedProfessionalService.rate }} per {{ selectedProfessionalService.interval.name }}</span>
                            <span>Quote Upon Request</span>
                            <!-- <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No rate added
                            </span> -->
                        </div>
                    </v-col>
                    <!-- Services Form -->
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>
<script>
    import { mdiCloseThick, mdiArrowLeftThinCircleOutline, mdiAccountSupervisorOutline, mdiMapMarkerOutline } from '@mdi/js'
    import GeneralMapComponent from '@/components/general/map/GeneralMapComponent.vue'
    import { WorkProfessionalServiceDetailController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'WorkProfessionalServiceDetailViewOverlayComponent',

        components: {
            GeneralMapComponent
        },

        props: {
            showDialog: {
                type: Boolean,
                required: true,
                default: false
            },

            professionalServiceToShow: {
                type: Object,
                required: true
            }
        },

        watch: {
            showDialog: {
                async handler (value) {
                    if (value) {
                        await this.getProfessionalServiceDetail()
                    } else {
                        this.selectedProfessionalService = {
                            currency: {},
                            industry: {},
                            interval: {},
                            work_location: {
                                id: 2
                            },
                            owner: {}
                        },
                        this.addresses = []
                        this.mapMarkers = []
                    }
                }
            }
        },

        data () {
            return {
                mdiCloseThick,
                mdiArrowLeftThinCircleOutline,
                mdiAccountSupervisorOutline,
                mdiMapMarkerOutline,
                mapMarkers: [],
                addresses: [],
                selectedProfessionalService: {
                    currency: {},
                    industry: {},
                    interval: {},
                    work_location: {
                        id: 2
                    },
                    owner: {}
                },
                workProfessionalServiceDetailController: null
            }
        },

        beforeMount () {
            if (!this.workProfessionalServiceDetailController) this.workProfessionalServiceDetailController = new WorkProfessionalServiceDetailController()
        },

        methods: {
            async getProfessionalServiceDetail () {
                try {
                    this.selectedProfessionalService = await this.workProfessionalServiceDetailController.retrieve(this.professionalServiceToShow.id)

                    this.addresses = this.selectedProfessionalService.geo_location
                    for (let index = 0; index < this.selectedProfessionalService.geo_location.length; index++) {
                        const address = this.selectedProfessionalService.geo_location[index];
                        const mapMarkerIndex = this.mapMarkers.findIndex(mapMarkerToFind => mapMarkerToFind.lat === this.address.lat && mapMarkerToFind.lng === this.address.lng)
                        if (mapMarkerIndex < 0) this.mapMarkers.push({ lat: address.lat, lng: address.lng })
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            hideProfessionalServiceOverlay () {
                this.$emit('hideProfessionalServiceOverlay')
            },
        }
    }
</script>